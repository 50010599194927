import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;

    }

    body {
        font-family: 'Space Grotesk', sans-serif;

        p, a {
            font-family: 'IBM Plex Sans Regular', serif;;

        }

        position: relative;
        //overflow: scroll;
    }


    ::-webkit-scrollbar {
        height: 0; //sehr sehr schwer, height auch 0px dann kommt nicht diese mysterious horiozntal white bar, bei iwie 1400px width xd?
        width: 0;
        background: none; /* make scrollbar transparent */
    }
`;

export default GlobalStyle;
