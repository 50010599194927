import React, {useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Badge from "@material-ui/core/Badge";
import closeBTN from "../../images/reward model icons/modal/close.png";
import Button from "@material-ui/core/Button";
import SubmittedPromo from "./SubmittedPromo";
import PendingPromo from "./PendingPromo";
import styled from "styled-components";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import CategoryIcon from '@material-ui/icons/Category';
import updatePromotionVideoLink from "../../utils/updatePromotionVideoLink";
import EditCampaignForm from "./EditCampaignForm";
import useNotifications from "src/hooks/useNotifications";
import {
    updateCampaignApiCall,
    deleteCampaignApiCall,
    updatePromotionApiCall,
    getPromotionsApiCall,
    downloadCampaignReportApiCall
} from "src/utils/apiCalls"


/**
 * @typedef {Object} Promotions
 * @property {number} id
 * @property {number} views_after_three_days
 * @property {number | null} views
 * @property {number} earned_amount_eur
 * @property {string} video_url
 * @property {string} created_at
 * @property {string} video_uploaded_at
 * @property {{ tiktok_username: string }} creator
 * @property {{ views_count: string }} promotion_stat_latest
 */

/**
 * @param {{
 *   succeeded: Promotion[],
 *   reviewed: Promotion[],
 *   submitted: Promotion[],
 *   pending: Promotion[]
 * }} promotions
 */

const AdminCampaign = ({campaign}) => {
    const song = campaign.song;
    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const {showSuccess, showError} = useNotifications();

    const [toggledDiv, setToggledDiv] = useState(null);
    const [viewCountInput, setViewCountInput] = useState({});

    const [editing, setEditing] = useState(false);
    const [deleteText, setDeleteText] = useState("Delete");
    const [endCampaignText, setEndCampaignText] = useState("End campaign");
    const [backgroundColor, setBackgroundColor] = useState("white");
    const [editMode, setEditMode] = useState(false);
    const [editModes, setEditModes] = useState({});

    const [modal, setModal] = useState(null);
    const [promotions, setPromotions] = useState({
        "succeeded": [],
        "reviewed": [],
        "submitted": [],
        "pending": []
    })

    const config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };
    }, [access_token]);

    const toggleEditMode = () => setEditMode((value) => !value);
    const formatBudget = (budget) => budget.toLocaleString('en-US');

    const toggleEditModeForPromo = (promoId) => {
        setEditModes((prevEditModes) => ({
            ...prevEditModes,
            [promoId]: !prevEditModes[promoId],
        }));
    };

    const handleViewCountInputChange = (e, promoId) => {
        const newViewCountInput = {...viewCountInput, [promoId]: Number(e.target.value)};
        setViewCountInput(newViewCountInput);
    };

    const campaignToTerritories = () => {
        const loc_result = campaign.territories.map((territory) => territory.name);
        return loc_result.toString();
    };

    function diff_hours(dt2, dt1) {
        return Math.round(Math.abs((dt2 - dt1) / (1000 * 60 * 60)));
    }

    const handleFraudClick = (promoId) => {
        window.confirm("Are you sure you want to mark this promotion as fraud? \nThis can't be undone!!!") && markPromotionAsFraud(promoId);
    }

    const markPromotionAsFraud = (promoId) => {
        axios.put(`/api/admin/promotions/${promoId}`, {status: 'fraud'}, config)
            .then((res) => {
                showSuccess(res.data?.message ?? "Promotion marked as fraud")
                setTimeout(() => window.location.reload(), 2000);
            })
            .catch((err) => {
                showError(err.response?.data?.message ?? "Something went wrong, please try again later");
            });
    };

    const getPromotions = (promotionType) => {
        setToggledDiv(promotionType);

        if (!promotions[promotionType].length) {
            getPromotionsApiCall(campaign.id, promotionType, config, dispatch, (res) => {
                setPromotions(prevState => ({
                    ...prevState,
                    [promotionType]: res.data.data.items
                }))
            })
        }
    }

    const downloadReport = (reportFormat) => {
        const newConfig = {
            ...config,
            responseType: "blob",
        };

        downloadCampaignReportApiCall('admin', campaign.id, reportFormat, newConfig, dispatch)
    }

    const updatePromotionViews = (e, promotionId) => {
        e.preventDefault();

        if (!e.target.viewsAfterThreeDays.value) {
            setEditMode(false);
            return null;
        }

        const payload = {
            views_after_three_days: e.target.viewsAfterThreeDays.value,
        };

        updatePromotionApiCall(promotionId, payload, config, dispatch, () => {
            setEditMode(false);

            setTimeout(() => window.location.reload(), 2000);
        })
    };

    return (
        <PromotionDiv bg_color={backgroundColor}>
            {campaign && (
                <>
                    <div className="header">
                        <HeaderChip
                            icon={<MusicNoteIcon/>}
                            label={`${song.artist} - ${song.title}`}
                        />
                    </div>
                    <div className="tags">
                        <div className="left" style={{}}>
                            <RectangularChip label={campaign.created_at} title={"Campaign creation date"}/>
                            <RectangularChip label={campaignToTerritories()} title={"Available territories"}/>
                            <Chip label={song.genre.name} title={"Genre"}></Chip>
                        </div>
                        <div className="right">
                            <RectangularChip
                                icon={<StarBorderOutlinedIcon/>}
                                label={campaign.max_level_id}
                                title={"Max level"}
                            />

                            <RectangularChip
                                icon={<RemoveRedEyeOutlinedIcon/>}
                                label={campaign.campaign_views.toLocaleString()} //finishedPromsViewCount
                                title={"Total view count of finished promotions"}
                            />
                            <RectangularChip
                                icon={<AttachMoneyIcon fontSize="small"/>}
                                label={`${formatBudget(campaign.budget_left_eur)} / ${formatBudget(campaign.budget_eur)}`}
                                title={"Left budget / Total budget"}
                            />
                        </div>
                    </div>
                    {editing &&
                        <EditCampaignForm campaign={campaign}/>
                    }

                    <div className="Options">
                        <div className="left">
                            <BlackButton
                                onClick={() => {
                                    setEditing(!editing);
                                }}
                                variant="contained"
                                color="secondary"
                            >
                                <span>Edit</span>
                            </BlackButton>
                            <BlackButton
                                onClick={() => endCampaignText === "Confirm" ? updateCampaignApiCall(campaign.id, {archived: true}, config, dispatch) : setEndCampaignText("Confirm")}
                                variant="contained"
                                color="secondary"
                            >
                                <span>{endCampaignText}</span>
                            </BlackButton>
                            <BlackButton
                                onClick={() => downloadReport('csv')}
                                variant="contained"
                                color="secondary"
                            >
                                <span>Dwnld Report CSV</span>
                            </BlackButton>
                            <BlackButton
                                onClick={() => downloadReport('pdf')}
                                variant="contained"
                                color="secondary"
                            >
                                <span>Dwnld Report PDF</span>
                            </BlackButton>
                            <BlackButton
                                onClick={(e) => updateCampaignApiCall(campaign.id, {campaign_update: true}, config, dispatch)}
                                variant="contained"
                                color="secondary"
                            >
                                <span>Update Stats</span>
                            </BlackButton>
                        </div>
                        <div className="right">
                            {campaign.status !== 'archived' ? (
                                <BlackButton
                                    onClick={deleteText === "Confirm" ? () => deleteCampaignApiCall(campaign.id, config, dispatch) : () => setDeleteText("Confirm")}
                                    variant="contained" color="secondary">
                                    <span>{deleteText}</span>
                                </BlackButton>
                            ) : (
                                <BlackButton variant="contained" color="secondary">
                                    <span>Archived</span>
                                </BlackButton>
                            )}
                        </div>
                    </div>
                    <div className="PromotionTypes">
                        <StyledButtonGroup>
                            {Object.keys(promotions).map((promotion_type) => (
                                <StyledUIButton
                                    key={promotion_type}
                                    onClick={() => toggledDiv !== promotion_type ? getPromotions(promotion_type) : setToggledDiv(null)}
                                    aria-label={`Show ${promotion_type.charAt(0).toUpperCase() + promotion_type.slice(1)} Videos`}
                                    active={(toggledDiv === promotion_type).toString()}
                                >
                                    <Badge badgeContent={campaign.promotions[promotion_type]} color="primary">
                                        {promotion_type.charAt(0).toUpperCase() + promotion_type.slice(1)} Videos
                                    </Badge>
                                </StyledUIButton>
                            ))}
                        </StyledButtonGroup>
                    </div>


                    {toggledDiv === "succeeded" && (
                        <ApprovedPromoDiv className="SucceededPromotions">
                            {promotions['succeeded'].map((promo) => {
                                /** @type {Promotion} */
                                return (
                                    <React.Fragment key={`promo-${promo.id}`}>
                                        <ApprovedPromoDiv>
                                            <StyledWrapperDiv
                                                style={{backgroundColor: "#62C352A8"}}
                                            >
                                                <Chip
                                                    style={{
                                                        marginLeft: "1rem",
                                                        backgroundColor: "transparent",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}

                                                    className="chip"
                                                    icon={<AccountCircleOutlinedIcon/>}
                                                    label={promo.creator.tiktok_username}
                                                />
                                                <Chip
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        flex: 1.5,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<EventOutlinedIcon/>}
                                                    label={promo.video_uploaded_at}
                                                />
                                                <Chip
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<HourglassEmptyIcon/>}
                                                    label={promo.views_after_three_days}
                                                />

                                                {/*<form style={{display: "inline-flex"}}>*/}
                                                {/*    <div style={{display: "inline-flex"}}>*/}
                                                {/*        <p onClick={() => toggleEditMode()}*/}
                                                {/*           style={{display: "inline-flex"}}>*/}
                                                {/*            {promo.views === null*/}
                                                {/*                ? () => {*/}
                                                {/*                    setViewCountInput(0);*/}
                                                {/*                }*/}
                                                {/*                : () => {*/}
                                                {/*                    setViewCountInput(promo.views);*/}
                                                {/*                }}*/}
                                                {/*        </p>*/}
                                                {/*    </div>*/}
                                                {/*</form>*/}

                                                <Chip
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    onClick={() => toggleEditMode()}
                                                    className="chip"
                                                    icon={<RemoveRedEyeOutlinedIcon/>}
                                                    label={(viewCountInput[promo.id] || promo?.promotion_stat_latest?.views_count) ?? "NA"}
                                                />
                                                <Chip
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<MonetizationOnOutlinedIcon/>}
                                                    label={promo.earned_amount_eur}
                                                />
                                                <form style={{marginLeft: "auto", marginRight: "0.5rem"}}
                                                      onSubmit={(e) => updatePromotionVideoLink(e, config, promo.id, dispatch, "succeeded")}>
                                                    <label>
                                                        <input type="text"
                                                               name="videolink"
                                                               placeholder="VideoLink"
                                                               defaultValue={promo.video_url}
                                                        />
                                                    </label>
                                                    <LinkButton
                                                        style={{backgroundColor: "black", marginLeft: "0.5rem"}}
                                                        variant="contained"
                                                        color="secondary"
                                                        type="submit"
                                                    >
                                                        Edit link
                                                    </LinkButton>
                                                </form>
                                                <LinkButton
                                                    style={{backgroundColor: "red", marginRight: "0.5rem"}}
                                                    variant="contained"
                                                    color="secondary"
                                                    type="submit"
                                                    onClick={() => handleFraudClick(promo.id)}
                                                >
                                                    Fraud
                                                </LinkButton>
                                            </StyledWrapperDiv>
                                        </ApprovedPromoDiv>
                                    </React.Fragment>
                                );
                            })}
                        </ApprovedPromoDiv>
                    )}

                    {toggledDiv === "reviewed" && (
                        <ApprovedPromoDiv className="ReviewedPromotions">
                            {promotions['reviewed'].map((promo) => {
                                /** @type {Promotion} */
                                return (
                                    <div
                                        key={`promo-${promo.id}`}
                                        style={{width: "100%"}}
                                    >
                                        <MODALE
                                            DISPLAY={(modal === promo.id) ? "flex" : "none"}
                                            style={{flexDirection: "column"}}
                                        >
                                            <div
                                                style={{
                                                    marginLeft: "auto",
                                                    height: "10%",
                                                    marginBottom: "1rem",
                                                }}
                                            >
                                                <img
                                                    // onClick={() => openModelHandler("")}
                                                    onClick={() => setModal(null)}
                                                    style={{height: "100%", cursor: "pointer"}}
                                                    src={closeBTN}
                                                />
                                            </div>
                                            <p style={{fontSize: "24px"}}>Manual decline</p>

                                            <div
                                                style={{
                                                    marginTop: "2rem",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <p style={{fontWeight: 500}}>
                                                    Are you sure to decline selected promotion?
                                                </p>
                                                <p>User: {promo.creator.tiktok_username}</p>
                                                <p>Link: {promo.video_url}</p>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginTop: "2.5rem",
                                                }}
                                            >
                                                <Button
                                                    style={{margin: 0}}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => updatePromotionApiCall(promo.id, {status: 'declined'}, config, dispatch, () => setModal(false))}
                                                >
                                                    Yes
                                                </Button>
                                                <div style={{width: "100%"}}/>
                                                <Button
                                                    style={{margin: 0}}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setModal(null)}
                                                >
                                                    No
                                                </Button>
                                            </div>
                                        </MODALE>

                                        <ApprovedPromoDiv>
                                            <StyledWrapperDiv
                                                style={{
                                                    backgroundColor:
                                                        diff_hours(
                                                            new Date(new Date().toUTCString()),
                                                            new Date(promo.video_uploaded_at)
                                                        ) < 72
                                                            ? "#00CBCD5E"
                                                            : "#FF5A39B0",
                                                }}
                                            >
                                                <Chip
                                                    style={{
                                                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                                                        marginRight: "1rem",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<AccountCircleOutlinedIcon/>}
                                                    label={promo.creator.tiktok_username}
                                                />
                                                <Chip
                                                    style={{
                                                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                                                        marginRight: "1rem",
                                                        flex: 1.5,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<EventOutlinedIcon/>}
                                                    label={promo.video_uploaded_at}
                                                />
                                                <Chip
                                                    style={{
                                                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<HourglassEmptyIcon/>}
                                                    label={(viewCountInput[promo.id] || promo?.promotion_stat_latest?.views_count) ?? "NA"}

                                                />
                                                <Chip
                                                    style={{
                                                        backgroundColor: "rgba(0, 203, 205, 0.01)",
                                                        flex: 1,
                                                        alignItems: "center",
                                                        justifyContent: "start"
                                                    }}
                                                    className="chip"
                                                    icon={<CategoryIcon/>}
                                                    label={promo.status}
                                                />

                                                <form style={{
                                                    display: "inline-flex",
                                                    marginRight: "1rem",
                                                    flex: 1,
                                                    alignItems: "center",
                                                    justifyContent: "start"
                                                }}
                                                      onSubmit={(e) => updatePromotionViews(e, promo.id)}
                                                >
                                                    <div style={{display: "inline-flex"}}>
                                                        {/*{!editMode &&*/}
                                                        {/*    (promo.views_after_three_days === null ? setViewCountInput(0)*/}
                                                        {/*            : setViewCountInput(promo.views_after_three_days)*/}
                                                        {/*    )}*/}
                                                        {editModes[promo.id] && (
                                                            <>
                                                                <input name="viewsAfterThreeDays" style={{
                                                                    display: "inline-flex",
                                                                    width: "5rem"
                                                                }}
                                                                       onChange={(e) => handleViewCountInputChange(e, promo.id)}
                                                                />
                                                                <button type={"submit"}
                                                                        style={{
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            marginLeft: "0.5rem",
                                                                        }}
                                                                >
                                                                    update
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </form>

                                                <LinkButton
                                                    style={{
                                                        backgroundColor: "#000000",
                                                    }}
                                                    onClick={() => toggleEditModeForPromo(promo.id)}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    <span>Add Views</span>
                                                </LinkButton>

                                                <LinkButton
                                                    style={{backgroundColor: "#000000", marginLeft: "1rem"}}
                                                    onClick={() => window.open(promo.video_url)}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    <span>Tiktok</span>
                                                </LinkButton>

                                                <form style={{marginLeft: "1rem"}}
                                                      onSubmit={(e) => updatePromotionVideoLink(e, config, promo.id, dispatch, "approved")}>
                                                    <label>
                                                        <input type="text"
                                                               name="videolink"
                                                               placeholder="VideoLink"
                                                               defaultValue={promo.video_url}
                                                        />
                                                    </label>
                                                    <LinkButton
                                                        style={{backgroundColor: "black", marginLeft: "1rem"}}
                                                        variant="contained"
                                                        color="secondary"
                                                        type="submit"
                                                    >
                                                        <span>Edit link</span>
                                                    </LinkButton>
                                                </form>
                                                <LinkButton
                                                    style={{
                                                        backgroundColor: "#D32424",
                                                        marginLeft: "1rem",
                                                        marginRight: "0.5rem",
                                                    }}
                                                    // onClick={() => openModelHandler(promo)}
                                                    onClick={() => setModal(promo.id)}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    <span>Decline</span>
                                                </LinkButton>
                                            </StyledWrapperDiv>
                                        </ApprovedPromoDiv>
                                    </div>
                                );
                            })}
                        </ApprovedPromoDiv>
                    )}

                    {toggledDiv === "submitted" && (
                        <ApprovedPromoDiv>
                            {promotions['submitted'].map((promo) => {
                                return (
                                    <SubmittedPromo
                                        promo={promo}
                                        key={promo.id}
                                    />
                                );
                            })}
                        </ApprovedPromoDiv>
                    )}

                    {toggledDiv === "pending" && (
                        <ApprovedPromoDiv>
                            {promotions['pending'].map((promo) => {
                                return (
                                    <PendingPromo
                                        promo={promo}
                                        key={promo.id}
                                    />
                                );
                            })}
                        </ApprovedPromoDiv>
                    )}

                    <div className="SubmittedSongs"/>

                    <div className="PendingVideos"/>
                </>
            )}
        </PromotionDiv>
    );
};

export default AdminCampaign;

const MODALE = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: ${(props) => props.DISPLAY};
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 2rem 3rem 2rem;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    z-index: 99999999999;

    max-height: calc(100vh - 210px);
    overflow-y: auto;

    @media only screen and (max-width: 1024px) {
        width: 80%;
    }

    ul {
        list-style: square;
        margin-left: 2rem;
    }
`;

const StyledWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 97%;
    height: 43px;
    margin: auto;

    button {
        width: auto !important;
    }
`;

const HeaderChip = styled(Chip)`
    background-color: #b1b1b1 !important;
    border-radius: unset !important;
    height: 42px !important;
    font-family: "IBM Plex Sans Regular" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 1.1rem !important;
    line-height: 26px !important;
`;

const RectangularChip = styled(HeaderChip)`
    height: 35px !important;
    font-weight: 400 !important;
    font-size: 0.8rem !important;
    line-height: 18px !important;
`;

const StyledUIButton = styled(Button)`
    background-color: ${(props => (props.active === 'true' ? '#000000' : '#f0f0f0'))} !important;
    color: ${(props) => (props.active === 'true' ? '#fff' : '#000')} !important;
    margin-top: 2rem !important;
`;

const StyledButtonGroup = styled(ButtonGroup)`
    width: 100% !important;
`;

const ApprovedPromoDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;

    .paperDIV {
        position: relative;
    }

    .closeIMG {
        position: absolute;
        top: 0px;
        right: 0px;
        margin: 10px;
        padding: 0px;
        width: 18px;
        cursor: pointer;
        opacity: 80%;
    }
`;

const PromotionDiv = styled.div`
    margin: 2rem 2rem 0rem 2rem;
    background-color: ${(props) => props.bg_color};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    .header {
        padding: 2rem 2rem 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .tags {
        padding: 0.2rem 2rem 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .left * {
            margin-right: 1rem;
        }

        .right * {
            margin-left: 1rem;
        }
    }

    .UpdateForm {
        display: flex;
        flex-direction: column;

        input {
            margin: 0.5rem;
        }

        select {
            margin: 0.5rem;
        }
    }

    .Options {
        padding: 0 2rem 0 1rem;
        display: flex;
        width: 100%;

        button {
            margin-left: 1rem;
        }

        .right {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .PromotionTypes {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin-top: 2rem;
        width: 50%;
    }
`;

const BlackButton = styled(Button)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    white-space: nowrap;
    height: 42px !important;
    width: 150px !important;
    background-color: black !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    font-family: "IBM Plex Sans Regular" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: small !important;
    line-height: 26px !important;
`;

const LinkButton = styled(Button)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    white-space: nowrap;
    height: 28px !important;
    width: 130px !important;
    font-family: "IBM Plex Sans Regular" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: x-small !important;
    line-height: 26px !important;
`;